/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { css } from '@superset-ui/core';
import { Global } from '@emotion/react';

export const GlobalStyles = () => (
  <Global
    // eslint-disable-next-line theme-colors/no-literal-colors
    styles={() => css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      strong,
      th {
        font-family: 'Inter';
      }
      body {
        font-family: 'Inter';
      }
      .ant-tabs-tab-active {
        background: rgb(242, 245, 248) !important;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: white !important;
      }
      .ant-steps-item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        gap: 14px;
      }
      .ant-btn-primary {
        color: black !important;
      }
      .ant-btn-primary:hover {
        color: white !important;
      }
      .ant-menu-item-selected span {
        background-color: #466df7;
        color: white;
        border-radius: 5px;
        display: flex !important;
        align-items: center;
        width: 100%;
        padding: 8px;
        margin-left: -6px !important;
        height: inherit;
        margin-top: 0px !important;
      }
      .ant-menu-item-selected span > svg {
        margin: auto;
      }
      .ant-tooltip {
        display: none;
      }
      .ant-menu-item .ant-menu-item-icon {
        transition: none !important;
      }
      .ant-menu {
        animation-duration: 0s !important;
        transition: none;
      }
      .ant-menu-item::after {
        animation-duration: 0s !important;
        transition: none !important;
      }
      .css-wke4wm.ant-menu-item a:after {
        transition: none !important;
      }
      .ag-ltr .ag-cell {
        --ag-cell-horizontal-border: solid #d7d7d799 !important;
      }
      .ant-switch-checked {
        background-color: #466df7;
      }
      .quotron-primary-button {
        background-color: #466df7 !important;
        border-radius: 8px !important;
        color: white !important;
        border: none;
        padding: 0 16px;
      }
      .quotron-primary-button:hover {
        background-color: #4465d9 !important;
        color: white !important;
      }
      .quotron-edit-icon:hover {
        cursor: pointer;
      }
      .quotron-text-button {
        background-color: white !important;
        border-radius: 8px !important;
        color: black !important;
        border: none;
        padding: 0 16px;
      }
      .quotron-disable-button {
        background-color: #e3e4e6 !important;
        border-radius: 8px !important;
        color: #6c6d78 !important;
        border: none;
        padding: 14px 24px;
      }
      .quotron-normal-button {
        background-color: #ffffff;
        border-radius: 8px !important;
        color: #466df7 !important;
        border: 1px solid #466df7;
        padding: 0px 16px;
        height: 40px;
        font-size: 14px;
        font-family: 500;
      }
      .quotron-active-border {
        border: 1px solid #b2d7fc;
        border-radius: 12px;
      }
      .quotron-18font {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #191c30;
      }
      .quotron-24font {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #191c30;
      }
      .quotron-36font {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        color: #191c30;
      }
      .quotron-18font-active {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #238cf6;
      }
      .quotron-border-table {
        height: 56px;
        left: 72px;
        top: 290px;
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 16px;
      }
      .quotron-stripe-input {
        height: 40px;
        background: #ffffff;
        border: 2px solid rgba(140, 141, 151, 0.5);
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #7c7c85;
        padding-left: 16px;
      }
      .quotron-primary-disable-button {
        background-color: #f2f2f2 !important;
        border-radius: 8px !important;
        color: #939393 !important;
      }
      .quotron-primary-active-button {
        background-color: #1118c7 !important;
        border-radius: 8px;
        color: white !important;
      }
      .quotron-cancel-button {
        background-color: white;
        border: none;
        color: #466df7;
        font-size: 16px;
        font-weight: 600;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: white !important;
      }
      #customizeButton:hover {
        cursor: pointer;
      }
      .hover-text:hover {
        cursor: pointer;
      }
      .introjs-tooltip {
        width: 404px !important;
        background: #ffffff;
        box-shadow: 0px 4px 30px rgba(145, 145, 145, 0.1) !important;
        border-radius: 12px !important;
        max-width: 500px;
      }
      .introjs-tooltip-header {
        padding-left: 70px;
        padding-top: 30px;
      }
      .introjs-dontShowAgain,
      .introjs-tooltiptext {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .introjs-nextbutton {
        display: flex !important;
        justify-content: center !important;
        color: white !important;
        background: #466df7 !important;
        text-shadow: none;
        width: 150px !important;
        height: 40px !important;
        align-items: center !important;
        border-radius: 8px;
        border: none !important;
        font-size: 16px !important;
        font-weight: 600 !important;
      }
      .introjs-button:focus {
        box-shadow: none !important;
      }
      .introjs-prevbutton {
        font-size: 16px !important;
        font-weight: 600 !important;
        display: flex !important;
        justify-content: center !important;
        height: 40px !important;
        align-items: center !important;
        color: #466df7 !important;
        background: white !important;
        width: 150px !important;
        border: none;
      }
      .introjs-nextbutton:hover {
        color: white;
        background: #1b3fbd !important;
      }
      .introjs-tooltipbuttons {
        border: none !important;
        padding: 20px !important;
      }
      .introjs-tooltip-title {
        display: flex !important;
        justify-content: center !important;
        font-weight: 600 !important;
        font-size: 22px !important;
      }
      .welcome-tooltip-title {
        display: flex !important;
        justify-content: center !important;
        font-weight: 600 !important;
        font-size: 22px !important;
      }
      .introjs-dontShowAgain {
        font-weight: 400;
        font-size: 16px !important;
      }
      .css-nuexuc-ImportData .contantWrap .fileUploadDiv span {
        color: white !important;
      }
      .ant-select-multiple .ant-select-selection-item-remove {
        display: flex !important;
        align-items: center;
      }
      .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background-color: white;
        border: none;
      }
      .ant-tabs-tab-btn: hover {
        color: #466df7 !important;
      }
      .ant-tabs-tab-active {
        border: solid 1px #446df7;
        border-radius: 8px !important;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #466df7 !important;
      }
      h1 {
        font-weight: 700 !important;
        font-size: 22px !important;
        line-height: 28px !important;
      }
      h2 {
        font-weight: 600 !important;
        font-size: 22px !important;
        line-height: 28px !important;
      }
      h3 {
        font-weight: 700 !important;
        font-size: 20px !important;
        line-height: 28px !important;
      }
      h4 {
        font-weight: 700;
        font-size: 16px;
      }
      h4.dis-text {
        color: rgba(0, 0, 0, 0.45);
      }
      h5 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
      }
      .h7 {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
      h8 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      h9 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
      h10 {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
      h11 {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
      h12 {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
      h13 {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
      h14 {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
      h15 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
      h16 {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }
      h17 {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-decoration: underline;
      }
      h18 {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
      h19 {
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
      }
      .quotron-notification-success {
        border-radius: 8px 8px 0px 0px !important;
        background: #fcfcfc !important;
        width: 882px !important;
        height: 100px !important;
        color: #6fb727 !important;
        display: flex !important;
        gap: 12px !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(111, 183, 39)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
      }

      .quotron-notification-error {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(220, 65, 65)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
        border-radius: 8px 8px 0px 0px !important;
        background: #fcfcfc !important;
        width: 882px !important;
        height: 100px !important;
        color: #dc4141 !important;
        display: flex !important;
        gap: 12px !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        stroke-dasharray: 10px;
      }
      .quotron-notification-warning {
        border-radius: 8px 8px 0px 0px !important;
        background: #fcfcfc !important;
        width: 882px !important;
        height: 100px !important;
        color: orange !important;
        display: flex !important;
        gap: 12px !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(255, 165, 0)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
      }
      .quotron-input {
        // background: #f7f9ff;
        border-radius: 8px;
        height: 50px;
        // border: none;
        font-size: 16px;
        font-weight: 400;
        color: #7c7c85;
        padding: 14px;
      }
      .quotron-inactive-text {
        color: #7c7c85;
      }
      .StripeElement {
        margin-top: 16px;
        border-radius: 8px;
        border: 2px solid rgba(140, 141, 151, 0.5);
        height: 40px;
        align-items: center;
        display: grid;
        padding-left: 16px;
        background: #ffffff;
      }
      .ant-modal-content {
        border-radius: 8px !important;
      }
      .ant-drawer {
        z-index: 999;
      }
    `}
  />
);
